import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/analysis/:id/review/rcp',
        name: 'ReviewRcp',
        component: () => import('../views/review/ReviewPcr.vue')
    },
    {
        path: '/analysis/:id/review/pcr',
        name: 'ReviewPcr',
        component: () => import('../views/review/ReviewPcr.vue')
    },
    {
        path: '/analysis/:id/review/antibody',
        name: 'ReviewAntibody',
        component: () => import('../views/review/ReviewAntibody.vue')
    },
    {
        path: '/',
        name: 'Home',
        redirect: {name: 'Analysis'},
        meta: {
            auth: true,
        }
    },
    {
        path: '/analysis',
        name: 'Analysis',
        component: () => import('../views/analysis/Index.vue'),
        meta: {
            auth: true,
        }
    },
    {
        path: '/analysis/:id/edit',
        name: 'AnalysisEdit',
        component: () => import('../views/analysis/update/Index.vue'),
        meta: {
            auth: true,
        }
    },
    {
        path: '/analysis/create',
        name: 'AnalysisCreate',
        component: () => import('../views/analysis/update/Index.vue'),
        meta: {
            auth: true,
        }
    },
    {
        path: '/analysis/:id',
        name: 'AnalysisView',
        component: () => import('../views/analysis/View.vue'),
        meta: {
            auth: true,
        }
    },
    {
        path: '/client',
        name: 'Client',
        component: () => import('../views/client/Index.vue'),
        meta: {
            auth: true,
        }
    },
    {
        path: '/doctor',
        name: 'Doctor',
        component: () => import('../views/doctor/Index.vue'),
        meta: {
            auth: true,
        }
    },
    {
        path: '/note',
        name: 'Note',
        component: () => import('../views/note/Index.vue'),
        meta: {
            auth: true,
        }
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('../views/user/Index.vue'),
        meta: {
            auth: true,
            role: 'admin',
        }
    },
    {
        path: '/gosuslugi',
        name: 'Gosuslugi',
        component: () => import('../views/gosuslugi/Index.vue'),
        meta: {
            auth: true,
            role: 'admin',
        }
    },
    {
        path: "*",
        name: 'PageNotFound',
        component: () => import('../views/PageNotFound.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (
        to.name === 'Login' &&
        store.getters.isAuth
    ) {
        next({ name: 'Home' });
    }

    if (
        store.getters.isAuth &&
        Object.prototype.hasOwnProperty.call(to.meta, "role") &&
        to.meta.role !== store.getters.getMe.role.value
    ) {
        next({ name: 'Home' });
    }

    if (
        to.matched.some(record => record.meta.auth) &&
        !store.getters.isAuth
    ) {
        next({ name: 'Login' });
    }
    next();
});

export default router
