<template>
  <div :class="{'open': toggle}" class="left-menu">
    <div class="flex w-20 h-16 cursor-pointer text-gray-icon mb-5" @click="toggle = !toggle">
      <svg v-if="!toggle" class="m-auto stroke-current" fill="none" height="64" viewBox="0 0 85 64" width="85"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M50 27H32" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path d="M46 32H32" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path d="M50 37H32" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>
      <svg v-else class="m-auto stroke-current" fill="none" height="32" viewBox="0 0 33 32" width="33"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5 11L12.5 20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path d="M12.5 11L21.5 20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>
    </div>

    <router-link
        v-for="(item, index) in items"
        :key="index"
        :class="item.class"
        :title="item.label"
        :to="item.to"
        active-class="text-active-icon"
        class="text-gray-icon">
      <div class="menu-item">
        <div v-html="item.svg"/>
        <div class="hideIfToggle">{{ toggle ? item.label : null }}</div>
      </div>
    </router-link>

    <div class="logout" @click="logout">
      <div>
        <svg class="m-auto stroke-current ml-2" fill="none" height="20" viewBox="0 0 16 20" width="16"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 19V1L14.5 3V17L6.5 19Z" stroke-linejoin="round" stroke-width="2"/>
          <path d="M9.5 10L9.5 9" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
          <path d="M6.5 3H1.5V17H6.5" stroke-linejoin="round" stroke-width="2"/>
        </svg>
      </div>
      <div class="hideIfToggle">{{ toggle ? 'Выйти' : null }}</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      toggle: false,
    };
  },
  mounted() {
    this.$root.$on('menuToggle', value => this.toggle = value)
  },
  computed: {
    ...mapGetters({
      user: 'getMe',
    }),
    items() {
      let items = [
        {
          to: {name: 'Analysis'},
          label: 'Анализы',
          svg: '<svg class="m-auto stroke-current" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 10H17L14 19L8 1L5 10H1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        {
          to: {name: 'Client'},
          label: 'Клиенты',
          svg: '<svg class="m-auto stroke-current" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 9H15.01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 9H9.01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        {
          to: {name: 'Note'},
          label: 'Примечания',
          svg: '<svg class="m-auto stroke-current" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 17H8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 13H8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 9H9H8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 2V8H20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        },
        {
          to: {name: 'Doctor'},
          label: 'Врачи',
          svg: '<svg class="m-auto stroke-current" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 3H3V10C3 11.5 4.8 14 8 14C11.2 14 13 11.5 13 10V3H10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 13.5V15.5C8 17.3333 9.1 21 13.5 21C17.9 21 19 17.3333 19 15.5V13.5" stroke-width="2"/><circle cx="19" cy="11" r="2" stroke-width="2"/></svg>',
        },
      ];

      if (this.user.role.value === 'admin') {
        items.push({
          to: {name: 'User'},
          label: 'Пользователи',
          svg: '<svg class="m-auto stroke-current" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        })
        items.push({
          to: {name: 'Gosuslugi'},
          label: 'Госуслуги',
          svg: '<svg class="m-auto stroke-current" width="24" height="24" viewBox="-1 -1 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15,1A14,14,0,1,1,1,15,14,14,0,0,1,15,1m0-1A15,15,0,1,0,30,15,15,15,0,0,0,15,0Z" fill="#cfcfcf"/><path d="M15.59,25.5a4.56,4.56,0,0,1-1.86-.36c-.06,0-1.4-.65-2.9-1.56s-2.69-1.81-2.74-1.85A4.66,4.66,0,0,1,7,20.43l.86-.51a3.69,3.69,0,0,0,.86,1s1.19.91,2.65,1.79,2.79,1.5,2.81,1.51a4,4,0,0,0,2.87,0s1.35-.63,2.81-1.51,2.64-1.78,2.65-1.79a4.42,4.42,0,0,0,1.45-2.64s.16-1.54.16-3.3-.15-3.29-.16-3.3a4.43,4.43,0,0,0-1.45-2.64s-1.19-.91-2.65-1.79S17,5.77,17,5.77a4,4,0,0,0-2.87,0s-1.35.63-2.8,1.51S8.71,9.05,8.7,9.06a3.71,3.71,0,0,0-.86,1L7,9.57a4.64,4.64,0,0,1,1.11-1.3s1.23-.94,2.74-1.85,2.85-1.53,2.9-1.56a5,5,0,0,1,3.71,0c.06,0,1.39.65,2.9,1.56S23,8.23,23.09,8.27a5.41,5.41,0,0,1,1.84,3.33c0,.06.16,1.59.16,3.4s-.15,3.34-.16,3.4a5.41,5.41,0,0,1-1.84,3.33s-1.23.94-2.74,1.85-2.85,1.53-2.9,1.56A4.56,4.56,0,0,1,15.59,25.5Z" fill="#cfcfcf"/><polygon points="4 12 8 12 8 13 5 13 5 18 4 18 4 12" fill="#cfcfcf"/><path d="M12,13a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3Z" fill="#cfcfcf"/><path d="M19,18a3,3,0,1,1,1.82-5.39l-.61.79A2,2,0,0,0,19,13a2,2,0,1,0,1.29,3.53l.64.77A3,3,0,0,1,19,18Z" fill="#cfcfcf"/></svg>',
        })
      }

      return items;
    }
  },
  methods: {
    logout() {
      this.$store
          .dispatch('logout')
          .then(() => this.$router.push({name: 'Login'}))
    },
  }
}
</script>

<style lang="scss" scoped>
.left-menu {
  background: #FFF;
  height: 100vh;
  position: fixed;
  flex-direction: column;
  display: flex;
  padding: 6px 3px;
  width: 86px;
  transition: all .2s ease-in-out;
  border-right: 1px solid #eeeeee;
  z-index: 10;

  .menu-item {
    display: flex;
    justify-content: flex-start;
    height: 40px;
    padding: 6px 24px;
    margin-bottom: 10px;

    > :last-child {
      padding-left: 22px;
      color: #000;
    }
  }

  .logout {
    padding: 6px 24px;
    display: flex;
    justify-content: flex-start;
    margin-top: auto;
    color: red;
    cursor: pointer;
    height: 40px;

    > :last-child {
      padding-left: 22px;
      color: #000;
      margin-top: -3px;
    }
  }

  .hideIfToggle {
    opacity: 0;
    transition: all .2s ease-in-out;
  }

  &.open {
    width: 300px;

    .hideIfToggle {
      opacity: 1;
      transition-delay: .2s;
    }
  }
}
</style>
