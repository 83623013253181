import Vue from 'vue';
import _ from 'lodash';

Vue.filter('documentName', function (name) {
  const docs = [
    {value: 'passport', text: 'Паспорт гражданина РФ'},
    {value: 'international_passport', text: 'Заграничный паспорт'},
    {value: 'birth_certificate', text: 'Свидетельство о рождении'},
    {value: 'residence_permit', text: 'Вид на жительство'},
    {value: 'foreign_citizen_passport', text: 'Паспорт иностранного гражданина'},
    {value: 'other', text: 'Иное'},
  ]

  const item = _.find(docs, ['value', name]);

  if (item) {
    return item.text;
  }

  return null;
});
