<template>
  <transition name="slide">
    <div v-if="isAuth" id="app" class="flex flex-row" style="background: #EFF2F8;">
      <Menu/>
      <div class="main" @click="menuToggle">
        <Header :user="user"/>
        <router-view class="content-wrapper"/>
      </div>
    </div>
    <router-view v-else/>
  </transition>
</template>

<script>
import Menu from "@/components/Menu/Menu.vue";
import Header from "@/components/Header.vue";
import {mapGetters} from 'vuex';

export default {
  title: 'Медикал - форте',
  name: "App",
  components: {
    Menu,
    Header,
  },
  computed: {
    ...mapGetters({
      isAuth: 'isAuth',
      user: 'getMe',
    }),
  },
  methods: {
    menuToggle() {
      this.$root.$emit('menuToggle', false);
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  margin-left: 86px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  margin: 32px;
  height: 100%;
  flex: 1 1 auto;
}
</style>
