import {
    login as loginAPI,
    logout as logoutAPI,
    me as meAPI,
} from '../../api/user';

export default {
    state: () => ({
        auth: false,
        me: null,
        user: null,
        users: null,
    }),

    mutations: {
        setAuth(state, value) {
            state.auth = value;
        },
        setMe(state, value) {
            state.me = value;
        },
    },

    getters: {
        isAuth(state) {
            return state.auth;
        },
        getMe(state) {
            return state.me;
        },
    },

    actions: {
        async login({commit}, payload) {
            const response = await loginAPI({...payload});

            if (response.status === 204) {
                await this.dispatch('me');
            } else {
                commit('setAuth', false);

                commit('setMe', null);
            }
        },
        async logout({commit}) {
            const response = await logoutAPI();

            if (response.status === 204) {
                commit('setAuth', false);

                commit('setMe', null);
            }
        },
        async me({commit}) {
            await meAPI()
                .then(response => {
                    commit('setAuth', true);

                    commit('setMe', response.data.data);
                })
                .catch(() => {
                    commit('setAuth', false);

                    commit('setMe', null);
                })
        },
    }
};
