// import Vue from "vue";
import axios from 'axios';
import store from '../store';
import router from '../router';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

api.defaults.withCredentials = true;

api.defaults.headers.common['Accept'] = 'application/json';

api.defaults.headers.post['Content-Type'] = 'application/json';

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response !== undefined) {

            // if (error.response.status === 422) {
            //     Object.keys(error.response.data.errors).map(item => {
            //         error.response.data.errors[item].map(messsage => {
            //             Vue.$toast.error(messsage);
            //         });
            //     });
            // }

            if (
                error.response.status === 401 &&
                store.getters.isAuth
            ) {
                store.commit('setAuth', false);

                store.commit('setMe', null);

                router.push({name: 'Login'});
            }

            if (error.response.status === 404) {
                router.push({name: 'PageNotFound'});
            }
        }

        return Promise.reject(error);
    },
);

export default api;
