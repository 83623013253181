import Vue from 'vue';

Vue.mixin({
    methods: {
        getNotify(type, title) {
            this.$toast(title, {
                type: type
            });
        },
        getErrorNotify(error) {
            if (error.response.status === 422) {
                Object.keys(error.response.data.errors).map(item => {
                    error.response.data.errors[item].map(messsage => {
                        this.getNotify('error', messsage)
                    })
                })
            }
        },
    }
})
