import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import titleMixin from './mixins/title'
import VueMoment from 'vue-moment'

import './assets/css/tailwind.css'
import './assets/css/app.scss'
import './filters'


require('./plugins')
require('./mixins/notify')


Vue.mixin(titleMixin)
Vue.config.productionTip = false


require(`moment/locale/ru`)

Vue.use(VueMoment, {
  moment: window.moment
})


store.dispatch('me')
  .finally(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
